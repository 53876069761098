import React from 'react';
import { useTranslation } from 'react-i18next';
import AccountsBg from '../img/AccountsBg.png';
import AccountsBg2 from '../img/AccountsBg2.png';
import iconManager from '../img/iconManager.png';
import iconMoney from '../img/iconMoney.png';
import iconLink from '../img/iconLink.png';
import iconWallet from '../img/iconWallet.png';
import iconBomb from '../img/iconBomb.png';
import iconWeb from '../img/iconWeb.png';
import iconList from '../img/iconList.png';
import iconInsurance from '../img/iconInsurance.png';
import iconGift from '../img/iconGift.png';
import iconFav from '../img/iconFav.png';
import iconArrows from '../img/iconArrows.png';
import { Link } from 'react-router-dom';
import { ActionRegisterMultiCta } from './ActionRegisterMultiCta';

const AccountsTypes = () => {
  const { t } = useTranslation();

  return (
    <div
      className="flex flex-col items-center content-center lg:px-42 px-[10px] lg:py-44 py-20 bg-no-repeat"
      style={{
        backgroundImage: `url(${AccountsBg}),url(${AccountsBg2})`,
        backgroundPosition: 'left, right 100px'
      }}
    >
      <div className="flex flex-col bg-white rounded-25  lg:border border-solid lg:border-gray-200 shadow-md p-20">
        <div className="font-700 text-37 text-center">
          {t('Типы')} <span className="text-blue">{t('счетов')}</span>
        </div>
        <div className="flex flex-col lg:flex-row justify-evenly lg:items-start items-center pt-29">
          <div className="flex flex-col max-w-289 text-left w-full mx-[5px]">
            <div className="flex flex-col border-black border-solid border-b  min-h-fit lg:min-h-[168px] mb-[10px]">
              <div className="font-700 text-25">{t('Начинающий инвестор')}</div>
              <div className="font-700 text-28 lg:text-31">7 000 - 140 000 ₽</div>
            </div>
            <span className="flex flex-row font-700 text-15 pb-18 items-center">
              <img
                className="mr-7"
                src={iconManager}
                alt="iconManager"
              />
              {t('Персональный менеджер')}
            </span>
            <span className="flex flex-row font-700 text-15 pb-18 items-center">
              <img
                className="mr-7"
                src={iconMoney}
                alt="iconMoney"
              />
              {t('Доступ к валютному рынку')}
            </span>
            <span className="flex flex-row font-700 text-15 pb-18 items-center">
              <img
                className="mr-7"
                src={iconLink}
                alt="iconLink"
              />
              {t('Доступ к сырьевому рынку')}
            </span>
            <span className="flex flex-row font-700 text-15 pb-18 items-center">
              <img
                className="mr-7"
                src={iconWallet}
                alt="iconWallet"
              />
              {t('Низкий порог входа')}
            </span>
            <span className="flex flex-row font-700 text-15 pb-18 items-center">
              <img
                className="mr-7"
                src={iconBomb}
                alt="iconBomb"
              />
              {t('Высокая волатильность')}
            </span>
            <ActionRegisterMultiCta align=" self-center lg:self-start" />
          </div>
          <div className="flex flex-col max-w-289 text-left w-full mx-[5px]">
            <div className="flex flex-col border-black border-solid border-b  min-h-fit lg:min-h-[168px] mb-[10px]">
              <div className="font-700 text-25 text-left">{t('Квалифицированный инвестор')}</div>
              <div className="font-700 text-28 lg:text-31">140 000 - 500 000 ₽</div>
            </div>
            <span className="flex flex-row font-700 text-15 pb-18 items-center">
              <img
                className="mr-7"
                src={iconManager}
                alt="iconManager"
              />
              {t('Персональный менеджер')}
            </span>
            <span className="flex flex-row font-700 text-15 pb-18 items-center">
              <img
                className="mr-7"
                src={iconMoney}
                alt="iconMoney"
              />
              {t('Доступ к валютному рынку')}
            </span>
            <span className="flex flex-row font-700 text-15 pb-18 items-center">
              <img
                className="mr-7"
                src={iconLink}
                alt="iconLink"
              />
              {t('Доступ к сырьевому рынку')}
            </span>
            <span className="flex flex-row font-700 text-15 pb-18 items-center">
              <img
                className="mr-7"
                src={iconWeb}
                alt="iconWallet"
              />
              {t('Доступ к фондовому рынку')}
            </span>
            <span className="flex flex-row font-700 text-15 pb-18 items-center">
              <img
                className="mr-7"
                src={iconList}
                alt="iconBomb"
              />
              {t('Составление инвест плана')}
            </span>
            <span className="flex flex-row font-700 text-15 pb-18 items-center">
              <img
                className="mr-7"
                src={iconInsurance}
                alt="iconBomb"
              />
              {t('Страхование счета')}
            </span>
            <ActionRegisterMultiCta align=" self-center lg:self-start" />
          </div>
          <div className="flex flex-col max-w-289 text-left w-full mx-[5px]">
            <div className="flex flex-col border-black border-solid border-b  min-h-fit lg:min-h-[168px] mb-[10px]">
              <div className="font-700 text-25">
                {t('Инвестор')} <span className="text-blue">PRO</span>
              </div>
              <div className="font-700 text-28 lg:text-31">500 000+ ₽</div>
            </div>
            <span className="flex flex-row font-700 text-15 pb-18 items-center">
              <img
                className="mr-7"
                src={iconManager}
                alt="iconManager"
              />
              {t('Персональный менеджер')}
            </span>
            <span className="flex flex-row font-700 text-15 pb-18 items-center">
              <img
                className="mr-7"
                src={iconMoney}
                alt="iconMoney"
              />
              {t('Доступ к валютному рынку')}
            </span>
            <span className="flex flex-row font-700 text-15 pb-18 items-center">
              <img
                className="mr-7"
                src={iconLink}
                alt="iconLink"
              />
              {t('Доступ к сырьевому рынку')}
            </span>
            <span className="flex flex-row font-700 text-15 pb-18 items-center">
              <img
                className="mr-7"
                src={iconWeb}
                alt="iconWallet"
              />
              {t('Доступ к фондовому рынку')}
            </span>
            <span className="flex flex-row font-700 text-15 pb-18 items-center">
              <img
                className="mr-7"
                src={iconGift}
                alt="iconBomb"
              />
              {t('Доступ к IPO / ICO')}
            </span>
            <span className="flex flex-row font-700 text-15 pb-18 items-center">
              <img
                className="mr-7"
                src={iconFav}
                alt="iconBomb"
              />
              {t('Построение инвест плана')}
            </span>
            <span className="flex flex-row font-700 text-15 pb-18 items-center">
              <img
                className="mr-7"
                src={iconArrows}
                alt="iconBomb"
              />
              {t('Хеджирование рисков')}
            </span>
            <ActionRegisterMultiCta align=" self-center lg:self-start" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountsTypes;
