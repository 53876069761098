import React from 'react';
import Header from '../components/Header';
import Intro from '../components/Intro';
import RunningLine from '../components/RunningLine';
import Actives from '../components/Actives';
import Steps from '../components/Steps';
import AccountsTypes from '../components/AccountsTypes';
import Payment from '../components/Payment';
import Review from '../components/Review';
import Awards from '../components/Awards';
import FirstStep from '../components/FirstStep';
import Footer from '../components/Footer';
import { useEffect } from 'react';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header></Header>
      <Intro></Intro>
      <RunningLine></RunningLine>
      <Actives></Actives>
      <Steps></Steps>
      <AccountsTypes></AccountsTypes>
      <Payment></Payment>
      <Review></Review>
      <Awards></Awards>
      <FirstStep></FirstStep>
      <Footer></Footer>
    </div>
  );
};

export default Home;
