import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import AboutUsIntro from '../components/AboutUsIntro';
import AboutMission from '../components/AboutMission';
import AboutAdvantages from '../components/AboutAdvantages';
import Contacts from '../components/Contacts';
import Security from '../components/Security';
import FirstStep from '../components/FirstStep';
import { useEffect } from 'react';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header></Header>
      <AboutUsIntro></AboutUsIntro>
      <AboutMission></AboutMission>
      <AboutAdvantages></AboutAdvantages>
      <Contacts></Contacts>
      <Security></Security>
      <FirstStep></FirstStep>
      <Footer></Footer>
    </div>
  );
};

export default About;
