import { Link } from 'react-router-dom';
import { PATH_LOG_IN, PATH_REGISTER, PATH_TRADING } from '../routing';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePlatformOptions } from '../hooks/use-platform-options';
import { usePlatformOptionsStore } from '../stores/platform-options';

export const ActionRegisterMultiCta = ({ align, bg, color, isStaticColor }) => {
  const { t } = useTranslation();

  const { platformOptions } = usePlatformOptionsStore();

  if (platformOptions.signedIn) {
    return (
      <a
        href={`${window.location.origin}/${PATH_TRADING}`}
        className={
          'font-600 px-44 py-15 my-30 lg:my-47 rounded-10 max-w-200 flex text-center justify-center text-[16px] cursor-pointer highlight-button hoverScale' +
          align +
          ' ' +
          (bg ? bg : 'bg-gradient-to-r from-gradientBlue1 to-gradientBlue2') +
          ' ' +
          (color ? color : 'text-white')
        }
      >
        {t('Торговля')}
      </a>
    );
  } else if (platformOptions.registrationAllowed) {
    return (
      <Link
        to={PATH_REGISTER}
        className={
          'font-600 px-44 py-15 my-30 lg:my-47 rounded-10 max-w-200 flex text-center justify-center text-[16px] cursor-pointer highlight-button hoverScale' +
          align +
          ' ' +
          (bg ? bg : 'bg-gradient-to-r from-gradientBlue1 to-gradientBlue2') +
          ' ' +
          (color ? color : 'text-white')
        }
      >
        {t('Регистрация')}
      </Link>
    );
  } else {
    return (
      <Link
        to={PATH_LOG_IN}
        className={
          'font-600 px-44 py-15 my-30 lg:my-47 rounded-10 max-w-200 flex text-center justify-center text-[16px] cursor-pointer highlight-button hoverScale' +
          align +
          ' ' +
          (bg ? bg : 'bg-gradient-to-r from-gradientBlue1 to-gradientBlue2') +
          ' ' +
          (color ? color : 'text-white') +
          ' ' +
          (isStaticColor ? '' : 'hoverEffectButton')
        }
      >
        {t('Войти')}
      </Link>
    );
  }
};
