import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/zoom';
import { Navigation } from 'swiper/modules';
import 'swiper/css/effect-cards';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { useTranslation } from 'react-i18next';
import Avatar1 from '../img/user1.jpg';
import Avatar2 from '../img/user2.jpg';
import Avatar3 from '../img/user3.jpg';
import Avatar4 from '../img/user4.jpg';
import Avatar5 from '../img/user5.jpg';
import Avatar6 from '../img/user6.jpg';
import ReviewBg from '../img/ReviewBg.png';

const Review = () => {
  const { t } = useTranslation();

  return (
    <div
      className="lg:px-[20px] flex flex-col lg:pb-39 pt-39 bg-contain bg-right-top bg-no-repeat"
      style={{ backgroundImage: `url(${ReviewBg})` }}
    >
      <div className="self-center font-700 text-25 lg:text-37 mb-21 pl-20">
        {t('Нам')} <span className="text-blue">{t('доверяют')}</span> {t('наши клиенты')}
      </div>
      <Swiper
        slidesPerView="auto"
        navigation={true}
        grabCursor={false}
        modules={[Navigation]}
        className="mySwiper"
        loop={false}
      >
        <SwiperSlide className=" max-w-320 min-h-[580px] flex">
          <div className="flex flex-col  items-start text-start  mx-15 py-28 px-19 border bg-white border-grey-300 rounded-15 shadow-md max-w-287 min-h-[577px]">
            <img
              className="w-[67px] h-[67px] rounded-full"
              src={Avatar1}
              alt="Avatar"
            />
            <div className="pt-23 pb-31 border-grey border-b">
              {t(
                'Я давно занимаюсь трейдингом и перепробовал множество платформ, но эта действительно выделяется. Интерфейс чрезвычайно удобен, а инструменты для анализа рынка — одни из лучших, что я видел. Платформа предоставляет глубокие данные и точные прогнозы, что помогает мне принимать обоснованные решения. Кроме того, поддержка специалистов всегда готова помочь, что немаловажно для профессионалов.'
              )}
            </div>
            <div className="mt-15">{t('Алексей Иванов')}</div>
          </div>
        </SwiperSlide>
        <SwiperSlide className=" max-w-320 min-h-[580px] flex">
          <div className="flex flex-col  items-start text-start  mx-15 py-28 px-19 border bg-white border-grey-300 rounded-15 shadow-md max-w-287 min-h-[577px]">
            <img
              className="w-[67px] h-[67px] rounded-full"
              src={Avatar2}
              alt="Avatar"
            />
            <div className="pt-23 pb-31 border-grey border-b">
              {t(
                'Я только начинаю свой путь в трейдинге, и эта платформа оказалась отличным помощником. Интерфейс очень простой и интуитивно понятный, что сильно облегчает процесс обучения. В любое время можно обратиться за помощью к специалистам, которые дают чёткие и полезные рекомендации. Это сильно помогло мне уверенно стартовать.'
              )}
            </div>
            <div className="mt-15">{t('Ержан Есболатов')}</div>
          </div>
        </SwiperSlide>
        <SwiperSlide className=" max-w-320 min-h-[580px] flex">
          <div className="flex flex-col  items-start text-start  mx-15 py-28 px-19 border bg-white border-grey-300 rounded-15 shadow-md max-w-287 min-h-[577px]">
            <img
              className="w-[67px] h-[67px] rounded-full"
              src={Avatar3}
              alt="Avatar"
            />
            <div className="pt-23 pb-31 border-grey border-b">
              {t(
                'Очень понравилась работа менеджеров, всё объяснили и помогли разобраться, так как до этого у меня не было никакого опыта. Плюс, команда поддержки всегда на связи и готова помочь, что делает процесс торговли ещё более комфортным. Благодаря их профессионализму и оперативной помощи, я чувствую себя уверенно.'
              )}
            </div>
            <div className="mt-15">{t('Гуля Мырзахметова')}</div>
          </div>
        </SwiperSlide>
        <SwiperSlide className=" max-w-320 min-h-[580px] flex">
          <div className="flex flex-col  items-start text-start  mx-15 py-28 px-19 border bg-white border-grey-300 rounded-15 shadow-md max-w-287 min-h-[577px]">
            <img
              className="w-[67px] h-[67px] rounded-full"
              src={Avatar4}
              alt="Avatar"
            />
            <div className="pt-23 pb-31 border-grey border-b">
              {t(
                'Как новичок, я был приятно удивлён, насколько легко освоить эту платформу. Всё сделано с учётом потребностей начинающих трейдеров. Интуитивно понятный интерфейс и доступная поддержка специалистов — огромные плюсы. Они всегда готовы ответить на вопросы и помочь разобраться в тонкостях торговли. Отличный выбор для тех, кто только начинает.'
              )}
            </div>
            <div className="mt-15">{t('Елдос Тлеубердиев')}</div>
          </div>
        </SwiperSlide>
        <SwiperSlide className=" max-w-320 min-h-[580px] flex">
          <div className="flex flex-col  items-start text-start  mx-15 py-28 px-19 border bg-white border-grey-300 rounded-15 shadow-md max-w-287 min-h-[577px]">
            <img
              className="w-[67px] h-[67px] rounded-full"
              src={Avatar5}
              alt="Avatar"
            />
            <div className="pt-23 pb-31 border-grey border-b">
              {t(
                'Платформа оказалась отличным выбором для меня. Я уже не новичок в трейдинге, и меня приятно удивила простота и удобство её использования. Все необходимые инструменты под рукой, а поддержка специалистов быстро реагирует на запросы. Это делает процесс торговли более гладким и менее напряженным.'
              )}
            </div>
            <div className="mt-15">{t('Саян Сатпаев')}</div>
          </div>
        </SwiperSlide>
        <SwiperSlide className=" max-w-320 min-h-[580px] flex">
          <div className="flex flex-col  items-start text-start  mx-15 py-28 px-19 border bg-white border-grey-300 rounded-15 shadow-md max-w-287 min-h-[577px]">
            <img
              className="w-[67px] h-[67px] rounded-full"
              src={Avatar6}
              alt="Avatar"
            />
            <div className="pt-23 pb-31 border-grey border-b">
              {t(
                'Я в восторге от этой платформы для трейдинга! Её интерфейс настолько интуитивно понятен, что мне не потребовалось много времени, чтобы начать эффективно использовать все функции. Кроме того, поддержка специалистов на высшем уровне: они всегда готовы ответить на любые вопросы и предоставить необходимую помощь. Это делает трейдинг ещё более удобным и менее стрессовым.'
              )}
            </div>
            <div className="mt-15">{t('Сауле Аманжолова')}</div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Review;
