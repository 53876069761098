import React from 'react';
import './Preloader.css';
import Logo from '../../img/logo/logo-white.svg';

const Preloader = () => {
  return (
    <div className="fullscreen-preloader">
      <div className="logo-container">
        <img
          src={Logo}
          alt="Logo"
          className="logo"
        />
      </div>
    </div>
  );
};

export default Preloader;
