import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { signInUser } from '../_api/request';
import Header from '../components/Header';
import PhoneNumberInput from '../components/PhoneNumberInput';
import eyeIcon from '../img/eyeIcon.svg';
import hideIcon from '../img/hideIcon.svg';
import { useLoginPageRouting } from '../hooks/use-platform-options';
import { PATH_REGISTER } from '../routing';
import { usePlatformOptionsStore } from '../stores/platform-options';
import Preloader from '../components/preloader';

const useDefaultEmail = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const defaultEmail = urlParams.get('email');
  return defaultEmail || '';
};

const Login = () => {
  const { t } = useTranslation();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isEmail, setIsEmail] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const email = useDefaultEmail();

  const formik = useFormik({
    initialValues: {
      login: email,
      password: 'abcabc'
    },
    validationSchema: Yup.object({
      login: isEmail
        ? Yup.string().email().required('Required')
        : Yup.string().required('Required'),
      password: Yup.string().min(3).max(50).required('Required')
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const result = await signInUser({
          login: values.login,
          password: values.password
        });

        if (result.success) {
          window.location.href = '/trading';
        } else {
          setIsLoading(false);
          toast.error(t('Ошибка Входа'));
        }
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        toast.error(t('Произошла ошибка'));
      }
    }
  });

  const { platformOptions } = usePlatformOptionsStore();
  const registerCtaVisible = platformOptions.registrationAllowed;
  useLoginPageRouting(platformOptions);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleAuthChange = () => {
    setIsEmail(!isEmail);
    if (!isEmail) {
      formik.setFieldValue('login', '');
    }
  };

  return (
    <div>
      {isLoading && <Preloader />}
      <Header />
      <form
        className="flex flex-col items-center pt-[186px]"
        onSubmit={formik.handleSubmit}
      >
        <span className="text-30 font-700">{t('Вход')}</span>
        <div className="flex flex-row min-w-293 mt-20 lg:mt-40">
          <button
            type="button"
            onClick={handleAuthChange}
            className={
              isEmail
                ? 'rounded-10 bg-gradient-to-r from-gradientBlue1 to-gradientBlue2 text-white self-center py-5 px-16'
                : 'rounded-10 bg-transperent text-grey self-center py-5 px-16 hoverScale'
            }
          >
            {t("По email'у")}
          </button>
          <button
            type="button"
            onClick={handleAuthChange}
            className={
              isEmail
                ? 'rounded-10 bg-transperent text-grey self-center py-5 px-16 hoverScale'
                : 'rounded-10 bg-gradient-to-r from-gradientBlue1 to-gradientBlue2 text-white self-center py-5 px-16'
            }
          >
            {t('По номеру телефона')}
          </button>
        </div>
        {isEmail && (
          <input
            value={formik.values.login}
            onChange={(e) => formik.setFieldValue('login', e.target.value)}
            type="text"
            className="border border-gray-200 rounded-10 mt-12 lg:mt-40 min-h-50 min-w-293 p-13"
            required
            placeholder={t('Эл. почта')}
          />
        )}
        {!isEmail && (
          <div className="max-w-[293px]">
            <PhoneNumberInput onChange={(phone) => formik.setFieldValue('login', phone)} />
          </div>
        )}
        <div className="relative">
          <input
            value={formik.values.password}
            onChange={(e) => formik.setFieldValue('password', e.target.value)}
            type={passwordVisible ? 'text' : 'password'}
            className="border border-gray-200 rounded-10 mt-12 min-h-50 min-w-293 p-13 pr-8"
            required
            placeholder={t('Пароль')}
          />
          <img
            src={!passwordVisible ? hideIcon : eyeIcon}
            alt={!passwordVisible ? 'hide-icon' : 'eye-icon'}
            className="absolute top-1/2 transform -translate-y-1/4 right-4 cursor-pointer w-[32px] h-[30px]"
            onClick={togglePasswordVisibility}
          />
        </div>
        <Link
          to="/ForgotPassword"
          className="font-300 underline text-12 mt-9 min-w-293 text-start hoverScale"
        >
          {t('Забыли пароль?')}
        </Link>
        <button
          type="submit"
          className={`font-600 text-19 px-78 py-14 mt-14 rounded-10 
          bg-gradient-to-r from-gradientBlue1 to-gradientBlue2 text-white 
          self-center cursor-pointer hoverScale hoverEffectButton`}
          disabled={!formik.isValid} // Заблокировать кнопку при загрузке
        >
          {isLoading ? t('Загрузка...') : t('Войти')}
        </button>
        {registerCtaVisible && (
          <span className="text-16 font-600 self-center mt-64">
            {t('Нет аккаунта?')}{' '}
            <Link
              to={PATH_REGISTER}
              className="font-600 underline text-18"
            >
              {t('Создать')}
            </Link>
          </span>
        )}
      </form>
    </div>
  );
};

export default Login;
