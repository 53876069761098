import React from 'react';
import { useTranslation } from 'react-i18next';
import AboutIntro from '../img/AboutIntro.png';
import AboutIntroStatick from '../img/AboutIntroStatick.png';
import AboutIntroBg from '../img/AboutIntroBg.png';
import { Link } from 'react-router-dom';
import { ActionRegisterMultiCta } from './ActionRegisterMultiCta';

const AboutUsIntro = () => {
  const { t } = useTranslation();

  return (
    <div
      className="flex flex-col lg:flex-row items-center justify-evenly lg:px-42 px-[10px] lg:pl-133 lg:pr-42 bg-contain bg-top-left bg-no-repeat mt-[100px] w-full"
      style={{ backgroundImage: `url(${AboutIntroBg})` }}
    >
      <div className="pr-0  flex flex-col">
        <div className="flex flex-col max-w-530">
          <div className="font-700 text-28 lg:text-48 text-center lg:text-right leading-[3rem]">
            {t('Ваш проводник в мир')}
          </div>
          <div className="text-blue font-700 text-48 lg:text-61 text-center lg:text-right">
            {t('ТРЕЙДИНГА')}
          </div>
          <div className="font-700 text-18 text-center lg:text-right">
            {t(
              'Совершенствуйте торговые навыки и открывайте для себя широкий спектр финансовых возможностей'
            )}
          </div>
          <ActionRegisterMultiCta align=" self-center lg:self-end" />
        </div>
      </div>
      <div
        className="max-w-380 lg:max-w-612 bg-no-repeat bg-center bg-contain"
        style={{ backgroundImage: `url(${AboutIntroStatick})` }}
      >
        <img
          src={AboutIntro}
          alt="intro-img"
          className="floating-image"
        />
      </div>
    </div>
  );
};

export default AboutUsIntro;
