import React from 'react';
import { useTranslation } from 'react-i18next';
import Security1 from '../img/Security1.png';
import Security2 from '../img/Security2.png';

const Security = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center text-center mt-30 lg:mt-10">
      <div className="text-37 font-700">
        {t('Гарантированная')} <span className="text-blue">{t('безопасность')}</span>
      </div>
      <div className="text-28 font-600 max-w-960 mt-17">
        {t(
          'Global Invesment Partners действует в полном соответствии с международным законодательством и стандартами регулирования и гарантирует нашим клиентам сохранность средств и защиту отрицательного баланса.'
        )}
      </div>
      <div className="flex flex-col lg:flex-row">
        <img
          src={Security1}
          alt="security-img"
          className="border border-solid border-gray-300 rounded-15 p-10 mt-10"
        />
        <img
          src={Security2}
          alt="security-img"
          className="border border-solid border-gray-300 rounded-15 p-10 mt-10"
        />
      </div>
    </div>
  );
};

export default Security;
