import React from 'react';
import { useTranslation } from 'react-i18next';
import Step1 from '../img/Step1.png';
import Step2 from '../img/Step2.png';
import Step3 from '../img/Step3.png';
import StepBg from '../img/StepBg.png';
import { Link } from 'react-router-dom';
import { ActionRegisterMultiCta } from './ActionRegisterMultiCta';

const Steps = () => {
  const { t } = useTranslation();

  return (
    <div
      className="flex flex-col lg:px-42 px-[10px] lg:mt-12 mt-[25px] bg-contain bg-right-bottom bg-no-repeat items-center"
      style={{ backgroundImage: `url(${StepBg})` }}
    >
      <div className="font-700 text-28 lg:text-37 text-center ">
        {t('Всего')} <span className="text-blue">{t('3 шага')}</span> {t('до финансового успеха')}
      </div>
      <div className="flex flex-col lg:flex-row w-full justify-evenly items-center">
        <div className="flex flex-col items-left  py-10 lg:py-26 max-w-320 items-center text-center lg:text-start lg:items-start rounded-15">
          <img
            src={Step1}
            alt="step-1"
            className="max-w-58"
          />
          <div className="font-700 text-25 pt-17 lg:pt-24 pb-10 lg:pb-17">{t('Регистрация')}</div>
          <div className="font-400 text-18">
            {t(
              'Быстрая и простая регистрация с персональным менеджером, который в течении 5ти рабочих дней рассмотрит вашу заявку'
            )}
          </div>
        </div>
        <div className="flex flex-col items-left  py-10 lg:py-26 max-w-320 rounded-15 items-center text-center lg:text-start lg:items-start">
          <img
            src={Step2}
            alt="step-1"
            className="max-w-58"
          />
          <div className="font-700 text-25 pt-17 lg:pt-24 pb-10 lg:pb-17">{t('Депозит')}</div>
          <div className="font-400 text-18">
            {t('Безопасный депозит с минимальной комиссией через партнерские платежные системы')}
          </div>
        </div>
        <div className="flex flex-col items-left  py-10 lg:py-26 max-w-320 rounded-15 items-center text-center lg:text-start lg:items-start">
          <img
            src={Step3}
            alt="step-1"
            className="max-w-58"
          />
          <div className="font-700 text-25 pt-17 lg:pt-24 pb-10 lg:pb-17">{t('Торговля')}</div>
          <div className="font-400 text-18">
            {t(
              'Ваш персональный специалист поможет ознакомиться с интерфейсом торговой платформы и начать торговать'
            )}
          </div>
        </div>
      </div>
      <ActionRegisterMultiCta align=" self-center" />
    </div>
  );
};

export default Steps;
