import React from 'react';
import Awards1 from '../img/Awards1.png';
import Awards2 from '../img/Awards2.png';
import Awards3 from '../img/Awards3.png';
import Awards4 from '../img/Awards4.png';

const Awards = () => {
  return (
    <div className="flex lg:flex-row p-16  flex-wrap items-center justify-around">
      <img
        src={Awards1}
        alt="Awards1"
        className="my-8 max-w-289"
      />
      <img
        src={Awards2}
        alt="Awards2"
        className="my-8 max-w-289"
      />
      <img
        src={Awards3}
        alt="Awards3"
        className="my-8 max-w-289"
      />
      <img
        src={Awards4}
        alt="Awards4"
        className="my-8 max-w-289"
      />
    </div>
  );
};

export default Awards;
