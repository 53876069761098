import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import ContactsBg from '../img/ContactsBg.png';
import locationLogo from '../img/locationLogo.png';
import mailLogo from '../img/mailLogo.png';
import MapPosition from './MapPosition';
import { sendFeedback } from '../_api/request';

const Contacts = () => {
  const { t } = useTranslation();
  const companyEmail = process.env.REACT_APP_COMPANY_EMAIL;
  const companyAddress = process.env.REACT_APP_COMPANY_ADRESS;

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      message: ''
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, 'Минимум 2 символа')
        .max(30, 'Максимум 30 символов')
        .required('Введите имя'),
      phone: Yup.string()
        .min(7, 'Номер слишком короткий')
        .max(14, 'Номер слишком длинный')
        .required('Введите номер телефона'),
      email: Yup.string().email('Неправильний формат email').required('Введите email'),
      message: Yup.string().min(5, 'Минимум 5 символов').max(1000, 'Максимум 1000 символов')
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        await sendFeedback(values);
        resetForm();
      } catch (error) {
        console.error(error);
      }
    }
  });

  const handlePhoneNumberChange = (e) => {
    let value = e.target.value;
    if (value.startsWith('+')) {
      value = '+' + value.substring(1).replace(/[^\d]/g, '');
    } else {
      value = value.replace(/[^\d]/g, '');
    }
    formik.setFieldValue('phone', value);
  };

  return (
    <div
      className="flex flex-col lg:px-42 px-[10px] lg:pr-42 lg:pb-118 bg-auto bg-left-bottom bg-no-repeat text-center lg:text-left mt-20 lg:mt-0"
      style={{ backgroundImage: `url(${ContactsBg})` }}
    >
      <div className="flex flex-col lg:flex-row px-47 py-58 justify-evenly bg-transparent rounded-25">
        <div className="flex flex-col lg:min-w-526">
          <div className="text-37 font-700">
            {t('Обратная')} <span className="text-blue">{t('связь')}</span>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className="flex flex-col min-w-80%">
              <input
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="name"
                type="text"
                placeholder={t('Имя')}
                className="mt-20 border border-solid border-gray-300 p-15 min-h-23 rounded-10"
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="text-red-500">{formik.errors.name}</div>
              ) : null}
              <input
                value={formik.values.phone}
                onChange={handlePhoneNumberChange}
                onBlur={formik.handleBlur}
                name="phone"
                type="tel"
                placeholder={t('Телефон')}
                className="mt-20 border border-solid border-gray-300 p-15 min-h-23 rounded-10"
              />
              {formik.touched.phone && formik.errors.phone ? (
                <div className="text-red-500">{formik.errors.phone}</div>
              ) : null}
              <input
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="email"
                type="text"
                placeholder="Email"
                className="mt-20 border border-solid border-gray-300 p-15 min-h-23 rounded-10"
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red-500">{formik.errors.email}</div>
              ) : null}
              <textarea
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="message"
                type="text"
                placeholder={t('Сообщение')}
                className="mt-20 border border-solid border-gray-300 p-15 min-h-155 rounded-10 "
              />
              {formik.touched.message && formik.errors.message ? (
                <div className="text-red-500">{formik.errors.message}</div>
              ) : null}
            </div>
            <button
              type="submit"
              className={`font-600 px-44 py-15 my-20 lg:my-37 rounded-10 max-w-200 bg-gradient-to-r from-gradientBlue1 to-gradientBlue2 text-white self-center lg:self-start cursor-pointer text-center hoverScale hoverEffectButton ${!formik.isValid ? 'opacity-50 pointer-events-none' : ''}`}
              disabled={!formik.isValid}
            >
              {t('Отправить')}
            </button>
          </form>
        </div>
        <div className="flex flex-col item-center lg:min-w-462">
          <div className="text-37 font-700">
            {t('Наши')} <span className="text-blue">{t('контакты')}</span>
          </div>
          <div className="flex flex-row items-center text-18 font-300 mt-25">
            <img
              src={mailLogo}
              alt="mail-logo"
              style={{ width: '19px', height: '15px', marginRight: '10px' }}
            />
            {companyEmail}
          </div>
          <div className="flex flex-row items-center text-18 font-300 mt-25">
            <img
              src={locationLogo}
              alt="location-logo"
              style={{ width: '19px', height: '27px', marginRight: '10px' }}
            />
            {companyAddress}
          </div>
          <MapPosition></MapPosition>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
