import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import Header from '../components/Header';
import PhoneNumberInput from '../components/PhoneNumberInput';
import eyeIcon from '../img/eyeIcon.svg';
import hideIcon from '../img/hideIcon.svg';
import { registerNewUser, signInUser } from '../_api/request';
import { usePlatformOptions, useRegisterPageRouting } from '../hooks/use-platform-options';
import toast from 'react-hot-toast';
import { usePlatformOptionsStore } from '../stores/platform-options';
import { PATH_LOG_IN } from '../routing';
import Preloader from '../components/preloader';

const Registration = () => {
  const { t } = useTranslation();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { platformOptions } = usePlatformOptionsStore();
  const [isLoading, setIsLoading] = useState(false);

  useRegisterPageRouting(platformOptions);

  const schema = Yup.object({
    name: Yup.string()
      .min(3, t('Слишком коротко!'))
      .max(50, t('Слишком длинно!'))
      .matches(/^\s*[\S]+(\s+[\S]+)+\s*$/gms, t('Пожалуйста, введите полное имя.'))
      .required(t('Обязательно для заполнения')),
    email: Yup.string()
      .min(3, t('Слишком короткий email!'))
      .max(50, t('Слишком длинный email!'))
      .email(t('Некорректный email'))
      .required(t('Обязательно для заполнения')),
    password: Yup.string()
      .min(3, t('Слишком короткий пароль!'))
      .max(50, t('Слишком длинный пароль!'))
      .required(t('Обязательно для заполнения')),
    confirmPassword: Yup.string()
      .min(3, t('Слишком короткий пароль!'))
      .max(50, t('Слишком длинный пароль!'))
      .oneOf([Yup.ref('password'), null], t('Пароли должны совпадать'))
      .required(t('Обязательно для заполнения')),
    promoCode: Yup.string().when([], {
      is: () => platformOptions?.promoCodeRequired,
      then: (schema) =>
        schema
          .min(3, t('Слишком короткий промокод!'))
          .max(50, t('Слишком длинный промокод!'))
          .required(t('Промокод обязателен'))
    }),
    rulesAgree: Yup.boolean().oneOf([true], t('Необходимо принять условия и политики')),
    phone: Yup.string()
      .min(9, t('Слишком короткий номер телефона!'))
      .max(20, t('Слишком длинный номер телефона!'))
      .required(t('Обязательно для заполнения'))
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      promoCode: '',
      rulesAgree: false,
      phone: ''
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      if (Object.keys(formik.errors).length > 0) {
        return;
      }
      setIsLoading(true);
      try {
        const result = await registerNewUser(values);

        if (result.success) {
          const loginResult = await signInUser({
            login: values.email,
            password: values.password
          });
          if (loginResult.success) {
            window.location.href = '/trading';
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
          toast.error(t('Ошибка регистрации'));
        }
      } catch (e) {
        setIsLoading(false);
        toast.error(e.message || 'Ошибка регистрации');
      }
    },
    validateOnMount: true,
    enableReinitialize: true
  });

  const { values, errors, touched, handleSubmit } = formik;

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    formik.validateForm();
  }, [platformOptions]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      {isLoading && <Preloader />}

      <Header />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        noValidate
        className="rounded-lg max-w-md w-full p-8"
      >
        <h1 className="text-2xl font-bold text-center mb-6">{t('Регистрация')}</h1>

        <div className="relative mb-[24px]">
          <input
            type="text"
            className="w-full border border-gray-300 rounded-lg p-3"
            placeholder={t('ФИО')}
            value={values.name}
            onChange={(e) => formik.setFieldValue('name', e.target.value)}
          />
          {touched.name && errors.name && (
            <div className="text-red-500 text-xs absolute mt-1">{errors.name}</div>
          )}
        </div>

        <div className="relative mb-[24px]">
          <input
            type="text"
            className="w-full border border-gray-300 rounded-lg p-3"
            placeholder={t('Эл. почта')}
            value={values.email}
            onChange={(e) => formik.setFieldValue('email', e.target.value)}
          />
          {touched.email && errors.email && (
            <div className="text-red-500 text-xs absolute mt-1">{errors.email}</div>
          )}
        </div>

        <div className="relative mb-[24px]">
          <PhoneNumberInput onChange={(value) => formik.setFieldValue('phone', value)} />
          {touched.phone && errors.phone && (
            <div className="text-red-500 text-xs absolute mt-1">{errors.phone}</div>
          )}
        </div>

        <div className="relative mb-[24px]">
          <input
            type={passwordVisible ? 'text' : 'password'}
            className="w-full border border-gray-300 rounded-lg p-3"
            placeholder={t('Пароль')}
            value={values.password}
            onChange={(e) => formik.setFieldValue('password', e.target.value)}
          />
          <img
            src={passwordVisible ? hideIcon : eyeIcon}
            alt="Toggle visibility"
            className="absolute top-3 right-3 cursor-pointer w-6 h-6"
            onClick={togglePasswordVisibility}
          />
          {touched.password && errors.password && (
            <div className="text-red-500 text-xs absolute mt-1">{errors.password}</div>
          )}
        </div>

        <div className="relative mb-[24px]">
          <input
            type={passwordVisible ? 'text' : 'password'}
            className="w-full border border-gray-300 rounded-lg p-3"
            placeholder={t('Повторите пароль')}
            value={values.confirmPassword}
            onChange={(e) => formik.setFieldValue('confirmPassword', e.target.value)}
          />
          <img
            src={passwordVisible ? hideIcon : eyeIcon}
            alt="Toggle visibility"
            className="absolute top-3 right-3 cursor-pointer w-6 h-6"
            onClick={togglePasswordVisibility}
          />
          {touched.confirmPassword && errors.confirmPassword && (
            <div className="text-red-500 text-xs absolute mt-1">{errors.confirmPassword}</div>
          )}
        </div>

        <div className="relative mb-[24px]">
          <div className="mt-12 text-12 text-left min-w-293">
            {platformOptions?.promoCodeRequired ? t('Обязательно') : t('Не обязательно')}
          </div>
          <input
            type="text"
            className="w-full border border-gray-300 rounded-lg p-3"
            placeholder={t('Промокод')}
            value={values.promoCode}
            onChange={(e) => formik.setFieldValue('promoCode', e.target.value)}
          />
          {touched.promoCode && errors.promoCode && (
            <div className="text-red-500 text-xs absolute mt-1">{errors.promoCode}</div>
          )}
        </div>

        <div className="flex items-start mb-[24px]">
          <input
            type="checkbox"
            className="w-4 h-4 mt-1 mr-2"
            checked={values.rulesAgree}
            onChange={(e) => formik.setFieldValue('rulesAgree', e.target.checked)}
          />
          <p className="text-sm">
            {t('Я принимаю')}{' '}
            <a
              href="."
              className="text-blue-600 underline"
            >
              {t('Условия соглашения')}
            </a>{' '}
            {t('и')}{' '}
            <a
              href="."
              className="text-blue-600 underline"
            >
              {t('Политики конфиденциальности')}
            </a>
            {t(' и подтверждаю, что я достиг совершеннолетия.')}
          </p>
        </div>
        {touched.rulesAgree && errors.rulesAgree && (
          <div className="text-red-500 text-xs mb-4">{errors.rulesAgree}</div>
        )}

        <button
          type="submit"
          className="font-semibold text-[19px] px-4 py-3 rounded-lg bg-gradient-to-r from-gradientBlue1 to-gradientBlue2 text-white cursor-pointer min-w-[260px] hover:scale-105 transition-transform w-full block"
        >
          {t('Зарегистрироваться')}
        </button>

        <p className="text-center text-sm font-semibold mt-6">
          {t('Уже есть аккаунт?')}{' '}
          <Link
            to={PATH_LOG_IN}
            className="text-blue-600 underline"
          >
            {t('Войти')}
          </Link>
        </p>
      </form>
    </div>
  );
};

export default Registration;
